.custom-height {
  height: calc(100vh - 200px);
}

.sensor-info {
  background-color: transparent !important;
}
.sensor-info:hover {
  background-color: rgba(147, 198, 234, 0.3) !important;
}
.button-sensor-info {
  margin-bottom: 0.50rem;
  padding: 0;
  border-radius: 8px;
  border: 0px solid transparent;
  text-align: left;
  background-color: transparent !important;
}